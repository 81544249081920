import { bindable, inject }          from 'aurelia-framework';
import { AppContainer }              from 'resources/services/app-container';
import { BaseFormViewModel }         from 'base-form-view-model';
import { FormSchema }                from 'modules/sealings/awaiting-payment/payments/view/form-schema';
import { SealingPaymentsRepository } from 'modules/sealings/awaiting-payment/payments/services/repository';

@inject(AppContainer, FormSchema, SealingPaymentsRepository)
export class ViewSealingPayment extends BaseFormViewModel {

    @bindable headerTitle = 'form.title.view-payment-details';
    @bindable formId      = 'sealings.awaiting-payment.payments.view-form';

    alert         = {};
    model         = {};
    schema        = {};
    buttonsSchema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param formSchema
     * @param repository
     */
    constructor(appContainer, formSchema, repository) {
        super(appContainer);

        this.formSchema = formSchema;
        this.repository = repository;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'sealings.awaiting-payment.manage',
            'sealings.awaiting-payment.view',
        ]);
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/sealings/awaiting-payment/payments/form.html');
    }

    /**
     * Handles activate event
     */
    activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        return this.fetchData(params).then(() => {
            this.schema        = this.formSchema.schema(this);
            this.buttonsSchema = this.formSchema.buttonsSchema(this);
        });
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    fetchData({ id }) {
        return this.repository.find(id).then((response) => {
            this.initialModel.assign(response);
            this.model.assign(response);
        });
    }

    /**
     * Redirects the user to the given lift's view form
     *
     * @param id
     */
    navigateToLift(id) {
        this.appContainer.router.navigateToRoute('lifts.lifts.view', { id: id });
    }

}
