import { inject }                           from 'aurelia-framework';
import { ConfirmPaymentCancellationDialog } from 'resources/elements/html-elements/dialogs/confirm-payment-cancellation-dialog';
import { DialogService }                    from 'aurelia-dialog';
import { Payment }                          from 'modules/administration/models/payment';
import { PaymentStatus }                    from 'modules/administration/models/payment-status';
import { PaymentTypesRepository }           from 'modules/management/payment-types/services/repository';

@inject(DialogService, PaymentTypesRepository)
export class FormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {};

    /**
     * Constructor
     *
     * @param dialogService
     * @param paymentTypesRepository
     */
    constructor(dialogService, paymentTypesRepository) {
        this.dialogService          = dialogService;
        this.paymentTypesRepository = paymentTypesRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns {Payment}
     */
    model() {
        return new Payment().assign(this.modelDefaults);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.customer = {
            type:       'text',
            key:        'customer',
            label:      'form.field.customer',
            size:       8,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.type_id = {
            type:         'select2',
            key:          'type_id',
            label:        'form.field.payment-type',
            size:         4,
            remoteSource: this.paymentTypesRepository.active.bind(this.paymentTypesRepository),
        };

        this.value = {
            type:            'text',
            key:             'value',
            label:           'form.field.amount',
            size:            4,
            required:        false,
            attributes:      {
                readonly: true,
            },
            valueConverters: [
                { name: 'currency' },
            ],
        };

        this.entity = {
            type:       'text',
            key:        'entity',
            label:      'form.field.entity',
            size:       4,
            required:   false,
            hidden:     viewModel.model.entity === null,
            attributes: {
                readonly: true,
            },
        };

        this.reference = {
            type:            'text',
            key:             'reference',
            label:           'form.field.reference',
            size:            4,
            required:        false,
            hidden:          viewModel.model.reference === null,
            attributes:      {
                readonly: true,
            },
            valueConverters: [
                { name: 'paymentReference' },
            ],
        };

        this.creator = {
            type:       'text',
            key:        'creator',
            label:      'form.field.requested-by',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.created_at = {
            type:            'text',
            key:             'created_at',
            label:           'form.field.request-date',
            size:            4,
            required:        false,
            attributes:      {
                readonly: true,
            },
            valueConverters: [
                { name: 'dateFormat' },
            ],
        };

        this.deadline = {
            type:            'text',
            key:             'deadline',
            label:           'form.field.payment-deadline',
            size:            4,
            required:        false,
            attributes:      {
                readonly: true,
            },
            valueConverters: [
                { name: 'dateFormat' },
            ],
        };

        this.procedure_type = {
            type:       'text',
            key:        'procedure_type',
            label:      'form.field.request-type',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.status = {
            type:       'text',
            key:        'status',
            label:      'form.field.status',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.paid_at = {
            type:            'text',
            key:             'paid_at',
            label:           'form.field.paid-at',
            size:            4,
            required:        false,
            hidden:          viewModel.model.status_id !== PaymentStatus.PAID,
            attributes:      {
                readonly: true,
            },
            valueConverters: [
                { name: 'dateFormat' },
            ],
        };

        this.observations = {
            type:     'textarea',
            key:      'observations',
            label:    'form.field.observations',
            size:     12,
            required: false,
        };

        return [
            [this.customer],
            [this.type_id, this.value],
            [this.entity, this.reference],
            [this.creator, this.created_at, this.deadline],
            [this.procedure_type, this.status, this.paid_at],
            [this.observations],
        ];
    }

    /**
     * Returns the buttons form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    buttonsSchema(viewModel) {
        this.backButton = {
            type:       'button',
            label:      'form.button.back',
            action:     () => viewModel.redirectToRoute({ name: 'sealings.awaiting-payment.index', params: { tab: 'payments' } }),
            attributes: {
                class: 'btn btn-light',
            },
            icon:       {
                attributes: {
                    class: 'icon-chevron-left',
                },
            },
        };

        this.submitButton = {
            type:       'button',
            label:      'form.button.confirm-payment',
            action:     () => viewModel.submit(),
            hidden:     viewModel.model.status_id !== PaymentStatus.AWAITING_PAYMENT,
            attributes: {
                class: 'btn btn-primary',
            },
            icon:       {
                right:      true,
                attributes: {
                    class: 'icon-chevron-right',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.backButton,
                this.submitButton,
            ],
        };

        return [
            [this.buttons],
        ];
    }

    /**
     * Handles the `cancel` action button
     *
     * @param viewModel
     *
     * @returns {*}
     */
    cancelPayment(viewModel) {
        this.dialogService
            .open({
                viewModel: ConfirmPaymentCancellationDialog,
                model:     {
                    resource: 'resource.sealings.sealing',
                    records: viewModel.model.lifts.split(', ').join('<br />'),
                    action:   {
                        method:     viewModel.repository.cancel.bind(viewModel.repository),
                        parameters: [viewModel.model.payment_id],
                    },
                },
            })
            .whenClosed(response => {
                if ( ! response.wasCancelled) {
                    viewModel.appContainer.router.navigateToRoute('sealings.sealing.index');
                }
            });
    }

}
