export class FormSchema {

    /**
     * Returns the form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.code = {
            type:       'text',
            key:        'code',
            label:      'form.field.lift-number',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.type = {
            type:       'text',
            key:        'type',
            label:      'form.field.procedure-type',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.certificate_deadline = {
            type:       'text',
            key:        'certificate_deadline',
            label:      'form.field.certificate-deadline',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.maintenance_company = {
            type:       'text',
            key:        'maintenance_company',
            label:      'form.field.maintenance-company',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.owner = {
            type:       'text',
            key:        'owner',
            label:      'form.field.owner',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.inspecting_entity = {
            type:       'text',
            key:        'inspecting_entity',
            label:      'form.field.inspecting-entity',
            size:       4,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        return [
            [this.code, this.type, this.certificate_deadline],
            [this.maintenance_company, this.owner, this.inspecting_entity],
        ];
    }

}
