import { BaseRepository } from 'modules/core/services/base-repository';

export class SealingPaymentsRepository extends BaseRepository {

    baseUrl = 'sealings/payments';

    /**
     * Cancels a record
     *
     * @param id
     *
     * @returns {*}
     */
    cancel(id) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/cancel`);
    }

    /**
     * Sets the record as paid
     *
     * @param id
     * @param data
     *
     * @returns {*}
     */
    pay(id, data) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/pay`, data);
    }

    /**
     * Downloads the given record's invoice
     *
     * @param id
     *
     * @returns {*}
     */
    invoice(id) {
        return this.httpClient.rawGet(`${this.baseUrl}/${id}/invoice`)
            .then(response => {
                if (response.status !== 200) {
                    throw { status: false, message: 'Error' };
                } else {
                    return response.blob();
                }
            });
    }

}
